// New Relic Browser agent
//@ts-nocheck

import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent'
import { Metrics } from '@newrelic/browser-agent/features/metrics'

const options = {
  init: {
    browser_monitoring: { enabled: $NEW_RELIC_APP_ID !== 'none' },
    distributed_tracing: { enabled: true },
    privacy: { cookies_enabled: true },
    ajax: { deny_list: ['bam.nr-data.net'] },
  },
  info: {
    beacon: 'bam.nr-data.net',
    errorBeacon: 'bam.nr-data.net',
    licenseKey: 'd64d3db899',
    applicationID: $NEW_RELIC_APP_ID,
    sa: 1,
  },
  loader_config: {
    accountID: '29005',
    trustKey: '25300',
    agentID: $NEW_RELIC_APP_ID,
    licenseKey: 'd64d3db899',
    applicationID: $NEW_RELIC_APP_ID,
  },
}

window.newrelic = new BrowserAgent({
  ...options,
  features: [Metrics],
})

export {}
